.dc-html-content-syntax-highlight pre {
  background-color: #002b36;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 1.5em;
  font-family: monospace;
}

code,
pre,
mark {
  background-color: #efeff5;
  mix-blend-mode: normal;
}

.dc-html-content-syntax-highlight pre code {
  white-space: pre-wrap;
}
