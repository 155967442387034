
// *************************************
//
//   DC Globals
//   -> Variables created for re-use globally
//
// *************************************

/**
 * Global Sass variables, all namespaced within "dc-". Must be included before
 * any other partials and a critical path dependency for the styleguide.
 */

/** Spatial */

$dc-multiplier: 2;
// Spatial lists for generating utility classes
$dc-spatial: 2 4 8 12 16 24 32 36 48 64 80 96 128;
$dc-spatial-common: 8 12 16 24 32 36 48 64;
$dc-measure: 160 192 256 320 480 640 800 960;
$dc-spatial-all: join(
  $list1: $dc-spatial,
  $list2: $dc-measure,
);
// Percentages list for generating utility classes
$dc-percentages: 1 5 10 15 20 25 30 40 44 50 60 70 75 80 90 100;
$dc-angles: 30 45 60 90 180 270 360;
$dc-z-indexes: 10 20 30 40 50 60 70 80 90 100 999;

/** Color */
$dc-blue: #06bdfc;
$dc-blue-dark: #009bd8;
$dc-blue-light: #60e7ff;
$dc-blue-text: #007bb6;
$dc-green: #03ef62;
$dc-green-dark: #00c53b;
$dc-green-light: #65ff8f;
$dc-green-text: #008700;
$dc-navy: #05192d;
$dc-navy-dark: #000820;
$dc-navy-light: #213147;
$dc-navy-text: #05192d;
$dc-orange: #ff931e;
$dc-orange-dark: #d87300;
$dc-orange-light: #ffbc4b;
$dc-orange-text: #b75900;
$dc-pink: #ff6ea9;
$dc-pink-dark: #dc4d8b;
$dc-pink-light: #ff95cf;
$dc-pink-text: #bf3072;
$dc-purple: #7933ff;
$dc-purple-dark: #5646a5;
$dc-purple-light: #974dff;
$dc-purple-text: #5646a5;
$dc-red: #ff5400;
$dc-red-dark: #dd3400;
$dc-red-light: #ff782d;
$dc-red-text: #c01100;
$dc-yellow: #fcce0d;
$dc-yellow-dark: #cfa600;
$dc-yellow-light: #ffec3c;
$dc-yellow-text: #907000;
$dc-white: #ffffff;
$dc-beige-100: #fffbf3;
$dc-beige-200: #f7f3eb;
$dc-beige-300: #efebe4;
$dc-beige-400: #e5e1da;
$dc-grey-100: #f7f7fc;
$dc-grey-200: #efeff5;
$dc-grey-300: #e8e8ee;
$dc-grey-400: #e1e1e8;

// Color map
$dc-colors: (
  'currentColor': currentColor,
  'transparent': transparent,
  'blue': $dc-blue,
  'blue-dark': $dc-blue-dark,
  'blue-light': $dc-blue-light,
  'blue-text': $dc-blue-text,
  'green': $dc-green,
  'green-dark': $dc-green-dark,
  'green-light': $dc-green-light,
  'green-text': $dc-green-text,
  'navy': $dc-navy,
  'navy-dark': $dc-navy-dark,
  'navy-light': $dc-navy-light,
  'navy-text': $dc-navy-text,
  'orange': $dc-orange,
  'orange-dark': $dc-orange-dark,
  'orange-light': $dc-orange-light,
  'orange-text': $dc-orange-text,
  'pink': $dc-pink,
  'pink-dark': $dc-pink-dark,
  'pink-light': $dc-pink-light,
  'pink-text': $dc-pink-text,
  'purple': $dc-purple,
  'purple-dark': $dc-purple-dark,
  'purple-light': $dc-purple-light,
  'purple-text': $dc-purple-text,
  'red': $dc-red,
  'red-dark': $dc-red-dark,
  'red-light': $dc-red-light,
  'red-text': $dc-red-text,
  'yellow': $dc-yellow,
  'yellow-dark': $dc-yellow-dark,
  'yellow-light': $dc-yellow-light,
  'yellow-text': $dc-yellow-text,
  'white': $dc-white,
  'beige-100': $dc-beige-100,
  'beige-200': $dc-beige-200,
  'beige-300': $dc-beige-300,
  'beige-400': $dc-beige-400,
  'grey-100': $dc-grey-100,
  'grey-200': $dc-grey-200,
  'grey-300': $dc-grey-300,
  'grey-400': $dc-grey-400,
);

/** Typography */

// Font family
$dc-font-family-sans-serif: 'Studio-Feixen-Sans', Arial;
$dc-font-family-mono: 'JetBrainsMonoNL', monospace;

// Font size
$dc-font-size-micro: 0.75rem;
$dc-font-size-small: 0.875rem;
$dc-font-size-h6: 1rem;
$dc-font-size-h5: 1.125rem;
$dc-font-size-h4: 1.25rem;
$dc-font-size-h3: 1.5rem;
$dc-font-size-h2: 2rem;
$dc-font-size-h1: 2.5rem;
$dc-font-size-base: 16px;

// Font weight
$dc-font-weight-bold: 800;
$dc-font-weight-light: 100;
$dc-font-weight-regular: 400;

// Line height
$dc-line-height-base: 1.5;
$dc-line-height-heading: 1.2;
$dc-line-height-large-heading: 1.05;


/** Border */
$dc-border-radius: 4px;


/** Box shadow */
$dc-box-shadow-border: 0 0 0 1px rgba(5,25,45,0.1);
$dc-box-shadow-color: rgba(5,25,45,0.3);
$dc-box-shadow-lg: 0 3px 5px -1px rgba(5,25,45,0.3);
$dc-box-shadow-md: 0 2px 4px -1px rgba(5,25,45,0.3);
$dc-box-shadow-sm: 0 1px 4px -1px rgba(5,25,45,0.3);
$dc-box-shadow-xl: 0 8px 12px -4px rgba(5,25,45,0.3);
$dc-box-shadow-xl-green: 0 4px 8px 0 rgba(54, 213, 125, 0.3);
$dc-box-shadow-xl-orange: 0 4px 8px 0 rgba(255, 148, 0, 0.3);
$dc-box-shadow-xl-purple: 0 4px 8px 0 rgba(132, 104, 196, 0.3);
$dc-box-shadow-xxl: 0 10px 20px 0 rgba(5,25,45,0.3);


/** Animation */
$dc-duration: 0.3s;
$dc-ease: cubic-bezier(0.77, 0, 0.175, 1);
$dc-transition: 0.3s cubic-bezier(0.77, 0, 0.175, 1);


/* Letter Spacings */
$dc-letter-spacing-base: 0px;
$dc-letter-spacing-medium-heading: -0.5px;
$dc-letter-spacing-large-heading: -1px;


/** Breakpoints */
$dc-bp-xs: 480px;
$dc-bp-sm: 768px;
$dc-bp-md: 992px;
$dc-bp-lg: 1200px;
$dc-bp-xl: 1366px;
$dc-bp-ws: 1680px;
$dc-bp-4k: 1920px;
$dc-bp-5k: 2560px;

$dc-bp-below-xs: 479px;
$dc-bp-below-sm: 767px;
$dc-bp-below-md: 991px;
$dc-bp-below-lg: 1199px;
$dc-bp-below-xl: 1365px;
$dc-bp-below-ws: 1679px;
$dc-bp-below-4k: 1919px;
$dc-bp-below-5k: 2559px;


$dc-breakpoints: (
  'xs': $dc-bp-xs,
  'sm': $dc-bp-sm,
  'md': $dc-bp-md,
  'lg': $dc-bp-lg,
  'xl': $dc-bp-xl,
);
$dc-breakpoints-hd: (
  'ws': $dc-bp-ws,
  '4k': $dc-bp-4k,
  '5k': $dc-bp-5k,
);
$dc-breakpoints-all: map-merge($dc-breakpoints, $dc-breakpoints-hd);
$dc-breakpoints-below: (
  'xs': $dc-bp-below-xs,
  'sm': $dc-bp-below-sm,
  'md': $dc-bp-below-md,
  'lg': $dc-bp-below-lg,
  'xl': $dc-bp-below-xl,
);
$dc-breakpoints-below-hd: (
  'ws': $dc-bp-below-ws,
  '4k': $dc-bp-below-4k,
  '5k': $dc-bp-below-5k,
);
$dc-breakpoints-below-all: map-merge($dc-breakpoints-below, $dc-breakpoints-below-hd);

$dc-sides: (
  't': top,
  'r': right,
  'b': bottom,
  'l': left,
);
